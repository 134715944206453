<template>
    <div class="setpassword content_getresponse">
        <router-link :to="{name : 'integrations'}" class="close_icon">
            <i class="fal fa-times"></i>
        </router-link>

        <div class="setpassowrd_inner container">

            <div class="top_content_getresponse">
                <img class="get_response_img" src="/img/integrations/mautic.png" alt="">
                <h2>Connect Mautic to Your {{ getSiteDetails.agency_name }} Account</h2>
                <p>Enter Your login credentials and Site URL</p>
            </div>

            <transition name="slide">
                <form v-if="!show_lists" class="basic_form clear" action="javascript:;">
                    <div class="input_field col-md-12">
                        <label >Mautic UserName</label>
                        <input v-model="username" type="text" placeholder="Username">
                    </div>
                    <div class="input_field col-md-12">
                        <label >Mautic Password</label>
                        <input v-model="password" type="password" placeholder="Password">
                    </div>
                    <div class="input_field col-md-12">
                        <label >Mautic Site URL</label>
                        <input v-model="url" type="text" placeholder="Site URL">
                    </div>
                    <div class="col-md-12 text-center btn_sec">
                        <button :disabled="disable_connect"  class="btn---cta btn-blue btn-round" @click.prevent="saveMautic()">
                            <span>Connect</span>
                        </button>
                    </div>
                </form>
            </transition>

        </div>
    </div>
</template>

<script>

import { saveMauticURL } from '@/config/config.js'
import { mapGetters } from 'vuex'
import http from '@/mixins/http-lib'

export default (
  {
    components: {

    },
    data () {
      return {
        username: '',
        url: '',
        password: '',
        lists: [],
        show_lists: false,
        selected_lists: [],
        disable_connect: false,
        expire: null,
        integration_id: null,
        reconnect: false,
        account_id: '',
        name: ''
      }
    },
    async created () {
      await this.getUserProfile()
      await this.fetchWhitelabelAppSettings()
    },
    computed: {
      ...mapGetters(['getProfile'])
    },
    methods: {
      saveMautic () {
        if ((this.username).trim() == '') {
          this.alertMessage('Please enter your Mautic Username.', 'error')
          return
        }
        if ((this.password).trim() == '') {
          this.alertMessage('Please enter your Mautic Password.', 'error')
          return
        } else if ((this.url).trim() == '') {
          this.alertMessage('Please enter your Mautic Site Url.', 'error')
          return
        }
        this.disable_connect = true
        const integration = this.getIntegrationPayload()
        http.post(saveMauticURL, {
          ...integration,
          username: this.username,
          password: this.password,
          url: this.url
        }).then(
          response => {
            if (response.data.tokenError && response.data.tokenError == true) {
              this.alertMessage(response.data.message, 'error')
              this.disable_connect = false
            } else {
              if (response.data.status) {
                this.alertMessage(response.data.message, 'success')
                this.getUserProfile()
                this.$router.push({
                  name: 'integrations'
                })
              } else {
                this.alertMessage(response.data.message, 'error')
                this.disable_connect = false
              }
              this.disable_connect = false
            }
          },
          response => {
            this.disable_connect = false
            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error')
          })
      }
      // window.location = saveMauticURL + "?id="+this.key+"&secret="+this.secret+"&url="+this.url
    }
    /* setActiveCampaign(){
                    if ((this.key).trim() == "") {
                        this.alertMessage('Please enter your ActiveCampaign API key.', 'error');
                        return;
                    }
                    else if((this.url).trim() == "")
                    {
                        this.alertMessage('Please enter your ActiveCampaign Connect Url.','error');
                        return;
                    }
                    this.disable_connect = true;
                    http.post(activeCampaignConnectURL, {
                        key: this.key,
                        url:this.url
                    }).then(
                        response => {
                            if (response.data.tokenError && response.data.tokenError == true) {
                                this.alertMessage(response.data.message, 'error');
                                this.disable_connect =false;
                            }
                            else {
                                if(response.data.status)
                                {
                                    this.lists = response.data.lists;
                                    this.show_lists = true;
                                    this.reconnect = response.data.reconnect;
                                    this.account_id = response.data.account_id;
                                    this.name = response.data.name;
                                    this.expire = response.data.expire;
                                    this.integration_id = response.data.integration_id;
                                }
                                else{

                                    this.alertMessage(response.data.message,'error');
                                }
                                this.disable_connect =false;
                            }
                        },
                        response => {
                            this.alertMessage('Unknown error occurred, administrator has been notified.', 'error');
                        });

                },

                saveActiveCampaignAccount(){
                    if(this.selected_lists.length == 0)
                    {
                        this.alertMessage('Please select atleast one list.','error');
                        return
                    }
                    http.post(activeCampaignSaveAccountURL, {
                        key: this.key,
                        url:this.url,
                        selected_lists: this.selected_lists,
                        reconnect:  this.reconnect,
                        account_id: this.account_id,
                        name: this.name,
                        integration_id:this.integration_id,
                        expire:this.expire
                    }).then(
                        response => {
                            if (response.data.tokenError && response.data.tokenError == true) {
                                this.alertMessage(response.data.message, 'error');
                            }
                            else {
                                if(response.data.status)
                                {
                                    this.alertMessage(response.data.message,'success');
                                    this.getUserProfile();
                                    this.$router.push({
                                        name: 'integrations'
                                    });
                                }
                                else{
                                    this.alertMessage(response.data.message, 'error');
                                }
                            }
                        },
                        response => {
                            this.alertMessage('Unknown error occurred, administrator has been notified.','error');
                        });
                }
            }
        } */}
)
</script>
